.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-box {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    width: 360px;
}

.login-box span {
    font-size: 40px;
    color: #007bff;
    margin-bottom: 25px;
    display: block;
}

.login-box input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin: 0 0 25px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}

.login-box input:focus {
    background: #dbdbdb;
}

.login-box button {
    text-transform: uppercase;
    outline: 0;
    background: #007bff;
    width: 100%;
    border: 0;
    border-radius: 20px;
    padding: 15px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-box .error {
    margin: 0 0 10px;
    font-size: 14px;
    color: red;
}

.login-box .login-error-message {
    margin: 0 0 15px;
    font-size: 14px;
    color: #e74c3c;
    background-color: #f8d7da;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #ffffff;
    width: 14px;
    height: 14px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
