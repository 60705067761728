.call-logs-container {
    display: flex;
    height: 100vh;
    background-color: #f5f5f5;
}

.content {
    flex: 1;
    margin-left: 10%;
    margin-right: 10%;
    padding: 20px;
    margin-top: 100px;
    overflow-y: auto;
}

.call-logs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f4f6f7;
    color: black;
    border-radius: 8px;
}

.call-logs-title {
    font-size: 18px;
    font-weight: bold;
}

.table-container {
    margin-top: 20px;
}

.call-logs-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.call-logs-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.call-logs-table td,
.call-logs-table th {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
}

.call-logs-table th {
    background-color: #f1f1f1;
    font-weight: bold;
    font-size: 16px;
    color: #252424;
}

.call-logs-table td {
    font-size: 16px;
    color: #0b0a0a;
    position: relative;
}
