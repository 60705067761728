.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 0 0 0;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 5%;
    z-index: 600;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    width: 4rem;
    margin-top: 60px;
}

.icon-link {
    text-decoration: none;
    margin: 10px 0;
}

.icon {
    width: 70%;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s;
    margin-top: 30%; 
    margin-left: 15%;
    font-size: 100px;
    font-weight: 600;
    color: #1976d2;
    transition: color 0.3s ease;
}

.icon:hover {
    color: #062c52;
}
