.user-details-container {
    display: flex;
    height: 100vh;
    background-color: #f5f5f5;
}

.content {
    flex: 1;
    margin-left: 10%;
    margin-right: 10%;
    padding: 20px;
    margin-top: 100px;
    overflow-y: auto;
}

.user-details {
    margin: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.details-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f4f6f7;
    color: black;
    border-radius: 8px;
}
.details-header .MuiButton-root {
    margin-left: 10px; 
}

.details-title {
    font-size: 18px;
    font-weight: bold;
    margin-right: auto;
   
}

.table-container {
    margin-top: 20px;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.user-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.user-table td,
.user-table th {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
}

.user-table th {
    background-color: #f1f1f1;
    font-weight: bold;
    font-size: 16px;
    color: #252424;
}

.user-table td {
    font-size: 16px;
    color: #0b0a0a;
    position: relative;
}

.phone-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-content {
    padding: 20px;
    text-align: center;
}

.button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.button-container .MuiButton-root {
    margin-right: 10px;
}

.call-icon {
    cursor: pointer;
    margin-left: 10px;
    background-color: #f0f0f0;
    border-radius: 50%;
    padding: 8px;
    border: 1px solid #ccc;
    transition: background-color 0.3s, border-color 0.3s;
}

.call-icon:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
}