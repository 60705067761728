.mainContainer {
    width: 60%;
    margin-top: 7rem;
    margin-left: 24rem;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    align-items: center;
  }
  
  .dashedBox {
    width: 100%;
    padding: 20px;
    border: 2px dashed #999;
    background-color: #f9f9f9;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
  
    @media (min-width: 768px) {
        width: 90%;
        margin: 1rem;
      }
  }
  
  .fileInput {
    margin-top: 10px;
    cursor: pointer;
  }
  
  .downloadLink {
    color: #007bff;
    text-decoration: underline;
    font-size: 1rem;
    margin-top: 20px;
    cursor: pointer;
  
    &:hover {
      color: #0056b3;
    }
  }
  .flexContainer{
    display: flex;
    justify-content: space-between;
    width:90%;
    gap:20px;
    align-items: center;
  }
  .inputField {
    border: 2px solid #ced4da;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 8px;
    width: 82.5%;
  }
  .selectedFile{
    color: #007bff;
  }