.rootContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 7rem;
  margin-left: 10rem;
  background-color:#ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding:20px
}
.tableContainer {
  display: flex;
  width: 100%;
  margin-top:20px
}
.text{
    color: rgb(119, 114, 114);
    font-size: 18px;
    font-weight: bold;
}
