.container {
  margin-left: 6%;
  padding: 20px;
  margin-top: 60px; 
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.details-title {
  font-weight: bold;
  font-size: 20px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: auto;
}

.data-table th,
.data-table td {
  padding: 15px;
  text-align: center;
  border: 1px solid #ddd;
}

.data-table th {
  background-color: #f4f4f4;
  color: #333;
}

.data-table td {
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-data {
  text-align: center;
  padding: 15px;
  font-size: 14px;
  color: #666;
}

.status-message {
  font-size: 16px;
}

.back-button {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.approve-button,
.reject-button {
  margin: 0 5px; /* Added consistent spacing between buttons */
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.approve-button {
  background-color: #28a745;
  color: white;
}

.reject-button {
  background-color: #dc3545;
  color: white;
}

.approve-button:hover {
  background-color: #218838;
}

.reject-button:hover {
  background-color: #c82333;
}

.status-buttons-container {
  display: flex; /* Align buttons horizontally */
  justify-content: center;
  gap: 10px; /* Add consistent spacing between buttons */
  align-items: center;
  white-space: nowrap; /* Prevent wrapping of buttons */
}
