.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #007bff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 1000;
    color: white;
}

.logoContainer {
    display: flex;
    align-items: center;
}

.logoutContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
}

.logoutButton {
    background-color: #ffffff;
    color: #007bff;
    border: 2px solid #ffffff;
    padding: 7px 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.logoutButton:hover {
    background-color: #f2f2f2;
    color: #555555;
}